import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import {MDCTextField} from '@material/textfield';
import { ToastrService } from 'ngx-toastr';
const MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY =  'lastAction';
declare var google:any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'CanalDenuncias';

  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
 public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router, private toast: ToastrService) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY,Date.now().toString());
  }

  ngAfterViewInit(): void {
    console.log('translate');
    this.loadScript(
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
      () => {
        (window as any).googleTranslateElementInit = () => {
          new google.translate.TranslateElement(
            { pageLanguage: 'es' },
            'google_translate_element'
          );
        };

        // Llamar explícitamente la función si el script ya está listo.
        if ((window as any).googleTranslateElementInit) {
          (window as any).googleTranslateElementInit();
        }
      }
    );
  }

  loadScript(src: string, callback?: () => void): void {
    if (document.querySelector(`script[src="${src}"]`)) {
      if (callback) callback();
      return; // Evitar cargar el script si ya existe.
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    script.onload = callback || (() => {});
    document.body.appendChild(script);
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    // const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout && localStorage.getItem('userTokenDenuncias'))  {
      localStorage.clear();
      this.router.navigate(['./login']);
      this.toast.warning('Sesión cerrada por inactividad', '', { "positionClass": "toast-top-right" });
    }
  }

}
