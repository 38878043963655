import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServicioDenunciaService } from './servicio-denuncia.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private servicioDenuncia: ServicioDenunciaService, private router: Router, private toast: ToastrService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(resolve => {
      let token = localStorage.getItem('userTokenDenuncias');
      this.servicioDenuncia.checkToken(token).subscribe((user: any) => {
        if (user.Result == 'OK') {
          console.log("##### User Guard: auth = true");
          resolve(true);
        } else {
          console.log("##### User Guard: auth = false");
          // console.log('User is not logged in');
          this.router.navigate(['login']);
          // this.router.navigate(['login'], {
          //   queryParams: {
          //     redirectUrl: state.url
          //   }
          // });
          if (user.tokenExpired) {
            this.toast.warning('Expired Token, please, login again to generate a new one', '', { "positionClass": "toast-top-right" });
          }

          resolve(false);
        }
      });
    });
  }

}
