<app-pasos></app-pasos>

<form id="pasos" [formGroup]="denuncia" (ngSubmit)="onSubmit()">
    <div id="headerPasos">
        <strong>Typology communication</strong>

        <p>To facilitate the further management of your communication, please help us to categorise it as best as possible.</p>
    </div>
    <div id="bodyPasos">
        <div class="form-group mb-2">

            <div id="divIzq">
                <!-- <p id="asterisco">Indique el tipo de relación que mantiene con el Colegio Oficial de Veterinarios del Principado de Asturias </p> -->
                <p id="asterisco">Indicate the type of relationship you have with the club</p>
                <!-- <select class="custom-select" id="inputGroupSelect01" formControlName="relacion" required>
                    <option selected disabled value="">Select an option</option>
                    <option *ngFor="let relacion of relaciones" [value]="relacion.titulo">{{relacion.titulo}}</option>
                </select> -->
                <select class="custom-select" id="inputGroupSelect01" formControlName="relacion" required>
                    <option selected disabled value="">Select an option</option>
                    <option value="Athlete">Athlete</option>
                    <option value="Trainer">Trainer</option>
                    <option value="Administrative staff">Administrative staff</option>
                    <option value="Medical staff">Medical staff</option>
                    <option value="Direction">General Manager</option>
                    <option value="Environment/player's family">Environment/player's family</option>
                </select>

            </div>



        </div>
        <div id="divDcha">
            <p>Do you remember the day of the event? </p>
            <input type="date" class="form-control" formControlName="fecha_suceso">
            <!--
                <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                    outsideDays="hidden">
                </ngb-datepicker>

                <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                    </span>
                </ng-template>
                <div id="divDcha" style="padding-right:20%">
            <label>¿Se acuerda de la ubicación? </label>

                <input type="text" class="form-control" id="lugar">
    
        </div>
                 -->
        </div>

        <div class="brdesktop">
            <br><br><br><br><br> <br><br><br> <br><br>
        </div>
        <div>
            <p id="asterisco">What would be the category related to communication?</p>
            <!-- <select class="custom-select " formControlName="categoria" required>
                <option selected disabled value="">Select an option</option>
                <option *ngFor="let categoria of categorias" [value]="categoria.titulo">{{categoria.titulo}}</option>
            </select> -->
            <select class="custom-select " formControlName="categoria" required>
                <option selected disabled value="">Select an option</option>
                <option value="Abuse of power situation">
                    Abuse of power situation
                    </option>
                <option value="Harassment situation">
                    Harassment situation
                    </option>
                <option value="Methodology and/or training load">
                    Methodology and/or training load                    
                </option>
                <option value="Injuries and recovery">Injuries and recovery</option>
                <option value="Rest periods">
                    Rest periods
                    </option>
                <option value="Other">
                    Other
                    </option>
            </select>

        </div>



        <div class="brdesktop">
            <br><br><br><br>
        </div>


        <!-- <div class="form-check">
            <input class="form-check-input" type="checkbox" [formControl]="aceptado1" required>
            <label class="form-check-label" for="invalidCheck">
                <span id="asterisco"> He leído los <a class="text-muted" target="_blank" routerLink="/terminos-uso" >Términos de uso</a> y los
                    acepto de acuerdo con lo dispuesto en dicho aviso. </span>
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" [formControl]="aceptado2" required>
            <label class="form-check-label" for="invalidCheck">
                <span id="asterisco"> <a class="text-muted" routerLink="/canales-externos" target="_blank" >Canales de denuncia externos.</a>
                </span>
            </label>
            <div class="invalid-feedback" *ngIf="!aceptado1 || !aceptado2">
                You must agree before submitting.
            </div>
        </div> -->
    </div>
    <br><br>
    <button [disabled]="!aceptado1.value || !aceptado2.value" type="submit" class="btn btn-primary" id="buttonDcha">Next</button>
    <br><br>

</form>
<div class="bottommobile" style="height: 100px;"></div>

<br>