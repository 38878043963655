<div id="containerLogin1">

    <!-- <button (click)="prueba()">prueba</button> -->

    <div id="izqLogin">
        <div class="text-center">
            <h2>Welcome to our communications channel</h2>
            <hr>
            <div class="text-left">
                <p>
                    Our Club is committed to providing an environment in which people's dignity is respected and value is placed on the identification, prevention, detection and removal of health disorders, including mental health. For this reason, we provide our athletes
                    with a series of tools that allow them to take care of their wellbeing and mental health, as well as to feel listened to and recognised.
                </p>
                <p>
                    Through our communication channel, players and other members of the club can report situations or behaviours that may pose a risk or are causing harm to the health and mental wellbeing of our athletes.
                </p>
                <img src="assets/cofounded.jpg" style="height: 80px;">
                <br>
                <img src="assets/logo-cus-originale.png" style="height: 80px;">
                <img src="assets/MITHRA_logo-08.png" style="height: 80px;">
                <img src="assets/AIC-logo.jpg" style="height: 80px;">
                <img src="assets/VNVB-logo-roseballonblanc.png" style="height: 80px;">
            </div>
        </div>
        <!-- <br>
        <div id="lista">
            <h3 style="padding-left:25px">Requisitos</h3>
            <ul>
                <li>Exposición detallada y clara acerca de los hechos.</li>
                <li>Lugar o momento de dicho suceso.</li>
                <li>Identificación de las personas involucradas/testigos.</li>
                <li>Aportar, si se considera necesario, documentos, archivos u otra información la cual sea relevante para dicha denuncia.</li>
            </ul>
        </div> -->

    </div>


    <div id="dchaLogin">

        <!-- <div class="text-center">
            <h4>Do you need help?</h4>
        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-danger" id="botonLogin" type="button" routerLink="/index">Alert</button>
        </div>
        <br><br><br> -->

        <div class="text-center">
            <h4>Club Login</h4>
        </div>

        <div class="center-container">
            <form class="login-form" [formGroup]="login">

                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="bi bi-person-circle"></i></span>
                    <input formControlName="user" type="text" class="col-xs-2">
                </div>

                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="bi bi-key-fill"></i></span>
                    <input formControlName="password" type="password" class="col-xs-2">
                </div>
            </form>

            <small class="incorrecto" *ngIf="erroneo">Usuario o contraseña incorrectos</small>

            <button type="button" id="botonLogin" class="btn btn-danger text-center" (click)="doLogin()">
                Login
                <div *ngIf="loading" style="float: left;">
                    <mat-spinner diameter="25"></mat-spinner>
                </div>
            </button>
            <br><br><br>
        </div>
    </div>
</div>





<div id="containerLogin2">
    <br>
    <div class="text-center">
        <h2>What is the process of communication?</h2>
        <br>
        <table class="table">
            <tr>
                <th>
                    <ul>
                        <li><i class="bi bi-chat-left-text"></i></li>
                        <li>Club</li>
                        <li><small>communicates the existence of the communications channel</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-file-earmark-plus"></i></li>
                        <li>Informant</li>
                        <li><small>submits a communication</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-share"></i></li>
                        <li>Communications channel</li>
                        <li><small>records communication</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-envelope-exclamation"></i></li>
                        <li>Informant</li>
                        <li><small>receives information about processing</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-check2-circle"></i></li>
                        <li>Club</li>
                        <li><small>complaint upheld. Deletion of data within the legal deadline</small></li>
                    </ul>
                </th>
            </tr>
        </table>
    </div>
    <div style="height: 75px;"></div>
</div>
<div id="containerLogin3">
    <br>
    <div class="text-center">
        <h2>What is the process of communication?</h2>
        <br>
        <table class="table">
            <tr>
                <th>
                    <ul>
                        <li><i class="bi bi-chat-left-text"></i></li>
                        <li>Club</li>
                        <li><small>communicates the existence of the communications channel</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-file-earmark-plus"></i></li>
                        <li>Informant</li>
                        <li><small>submits a communication</small></li>
                    </ul>
                </th>
            </tr>
            <tr>
                <th>
                    <ul>
                        <li><i class="bi bi-share"></i></li>
                        <li>Communications channel</li>
                        <li><small>records communication</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-envelope-exclamation"></i></li>
                        <li>Informant</li>
                        <li><small>receives information about processing</small></li>
                    </ul>
                </th>
            </tr>
            <tr>
                <th>
                    <ul>
                        <li><i class="bi bi-check2-circle"></i></li>
                        <li>Club</li>
                        <li><small>complaint upheld. Deletion of data within the legal deadline</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li></li>
                        <li><small></small></li>
                    </ul>
                </th>
            </tr>
        </table>
    </div>
    <div style="height: 120px;"></div>
</div>