<app-pasos></app-pasos>
<form id="pasos" [formGroup]="denuncia" (ngSubmit)="onSubmit()">
    <div id="headerPasos">
        <strong>Detailed information</strong>

        <p>Describe in as much detail as possible the event you wish to report, giving as much information and attaching, if available, evidence. </p>
    </div>

    <div id="bodyPasos">
        <div>
            <p id="asterisco">Have you discussed this with any member of management?</p>
            <input type="radio" value="Yes" formControlName="comentado" required><label>Yes</label>
            <br>
            <input type="radio" value="No" formControlName="comentado" required><label>No</label>
            <br>
            <input type="radio" value="Don't Remember" formControlName="comentado" required><label>I don't remember</label>
        </div>
        <br>

        <div>
            <p id="asterisco">Do you think any of the following people are involved?</p>
            <!-- <select class="custom-select" formControlName="implicados">
                <option selected disabled value="">Select an option</option>
                <option *ngFor="let pos of posiblesimplicados" [value]="pos.titulo">{{pos.titulo}}</option>
            </select> -->
            <select class="custom-select" id="inputGroupSelect01" formControlName="implicados" required>
                <option selected disabled value="">Select an option</option>
                <option value="Member of the management team">
                    Member of the management team
                    </option>
                <option value="Trainers">Trainers</option>
                <option value="Technical staff">
                    Technical staff
                    </option>
                <option value="Medical equipment">
                    Medical equipment
                    </option>
                <option value="Players">
                    Players
                    </option>
                <option value="Other">
                    Other
                    </option>
            </select>
        </div>
        <br><br> <br><br>

        <div class="text-center">
            <p>Indicate the person(s) involved in the communication</p>
            <table class="table table-striped">
                <thead class="text-center">
                    <tr class="headerdesktop">
                        <th id="asterisco">Name</th>
                        <th id="asterisco">Position</th>
                        <th id="asterisco">Events</th>
                        <th>Telephone/Email</th>
                        <th>Address</th>
                        <th></th>
                    </tr>
                    <tr class="headermobile" *ngFor="let implicadoForm of implicadoFormArray.controls; let i = index">
                        <th id="asterisco">Name</th>
                        <th id="asterisco">Position</th>
                        <th id="asterisco">Events</th>
                        <th>Telephone/Email</th>
                        <th>Address</th>
                        <th style="border-bottom 1px solid grey"></th>
                        <div style="height: 25px"></div>
                    </tr>
                </thead>
                <tbody class="text-left">
                    <tr class="p-3 mb-2 bg-light text-dark" *ngFor="let implicadoForm of implicadoFormArray.controls; let i = index" [formGroup]="implicadoForm">
                        <td><input type="text" class="form-control" formControlName="nombre_implicado" required> </td>
                        <td><input type="text" class="form-control" formControlName="cargo_implicado" required></td>
                        <td><input type="text" class="form-control" formControlName="hechos_acontecidos_implicado" required></td>
                        <td><input type="text" class="form-control" formControlName="contacto_implicado"></td>
                        <td><input type="text" class="form-control" formControlName="domicilio_implicado"></td>
                        <td><button type="button" (click)="eliminarImplicado(i)" id="addRowChild" class="btn btn-outline-danger">Remove</button></td>
                        <div class="headermobile" style="height:25px"></div>

                    </tr>
                </tbody>
            </table>
            <button type="button" (click)="agregarImplicado()" id="addRowChild" class="btn btn-outline-primary">Add those involved</button>
        </div>
        <br><br>

        <div class="text-center">
            <label class="testigosFuente">Are there witnesses?</label><br>
            <input type="radio" value="1" formControlName="testigos" required> Yes
            <input type="radio" value="0" formControlName="testigos" required> No
            <br>
            <div *ngIf="denuncia.get('testigos').value == 1">
                <table class="table table-striped" id="my-table">
                    <thead class="text-center">
                        <tr class="headerdesktop">
                            <th id="asterisco">Name</th>
                            <th id="asterisco">Events</th>
                            <th>Telephone/Email</th>
                            <th>Address</th>
                            <th></th>
                        </tr>
                        <tr class="headermobile" *ngFor="let testigoForm of testigoFormArray.controls; let j = index">
                            <th id="asterisco">Name</th>
                            <th id="asterisco">Events</th>
                            <th>Telephone/Email</th>
                            <th>Address</th>
                            <th style="border-bottom 1px solid grey"></th>
                            <div style="height: 25px"></div>
                        </tr>
                    </thead>
                    <tbody class="text-left">
                        <tr class="p-3 mb-2 bg-light text-dark" *ngFor="let testigoForm of testigoFormArray.controls; let j = index" [formGroup]="testigoForm">
                            <td><input type="text" class="form-control" formControlName="nombre_testigo" required></td>
                            <td><input type="text" class="form-control" formControlName="hechos_acontecidos_testigo" required></td>
                            <td><input type="text" class="form-control" formControlName="contacto_testigo"></td>
                            <td><input type="text" class="form-control" formControlName="domicilio_testigo"></td>
                            <td><button type="button" (click)="eliminarTestigo(j)" id="addRowChild" class="btn btn-outline-danger">Remove</button></td>
                            <div class="headermobile" style="height:25px"></div>
                        </tr>
                    </tbody>

                </table>

                <button type="button" (click)="agregarTestigo()" class="btn btn-outline-primary" id="addRowChild">Add those witnesses</button>
            </div>
        </div>
        <br><br>

        <div class="text-center">
            <p id="asterisco">Explain the event in detail </p>
            <textarea id="detalles" [class.is-invalid]="explicacionSucesoNoValido" formControlName="explicacion_suceso" class="form-control" rows="6" cols="155" required></textarea>
            <small *ngIf="explicacionSucesoNoValido" class="text-danger">
                Please, fill the required field
            </small>
        </div>

        <br><br>

        <div class="mb-3" class="text-center">
            <label for="formFileSm" class="form-label">Do you wish to attach any additional documents?</label>
            <br>
            <input id="formFileSm" type="file" (change)="fileChangeEvent($event)" multiple>
            <!-- <div *ngIf="previsualizacion">
                <img [src]="visualizar" alt="" class="previsualizar ml-2 mb-2" *ngFor="let visualizar of previsualizacion">
            </div> -->
            <br><br>
            <small>PDF documents, videos, jpg or png images.
                <br>(More than one file can be added)</small>
        </div>

    </div>
    <br><br>
    <button class="btn btn-primary" (click)="anterior()">Previous</button>
    <!-- <button type="submit" class="btn btn-primary" routerLink="/uno">Anterior</button> -->

    <input type="submit" class="btn btn-primary" id="buttonDcha" value="Next">
    <!-- <input type="submit" class="btn btn-primary" id="buttonDcha" routerLink="/tres" value="Siguiente"> -->

</form>
<div class="bottommobile" style="height: 80px;"></div>
<br><br>