import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicioDenunciaService } from '../../../services/servicio-denuncia.service';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.css']
})
export class ConfirmacionComponent implements OnInit {

  public codigo;
  public id;
  public denuncia;
  public fecha_denuncia;
  public implicados;
  public testigos;

  constructor(private aroute: ActivatedRoute, private servicioDenuncia: ServicioDenunciaService) { }

  ngOnInit(): void {

    this.aroute.params.forEach( p => this.codigo = p['id'] );
    this.getDenuncia();

  }

  getDenuncia(){

    this.servicioDenuncia.getDenunciaByCodigo(this.codigo).subscribe( res => {
      this.denuncia = res[0];
      this.id = this.denuncia.id_denuncia;

      let fecha = new Date(parseInt(this.denuncia.fecha_denuncia)).toISOString();
      this.fecha_denuncia = fecha.slice(0,4) + '-' + fecha.slice(5,7) + '-' + fecha.slice(8,10);

      // this.sendEmail();
      this.getImplicados();
    });

  }

  sendEmail() {
    this.servicioDenuncia.sendEmail(this.codigo).subscribe( res => {
      console.log('Estado del email: ' + res);
    });
  }

  getImplicados(){

    this.servicioDenuncia.getImplicado(this.id).subscribe( res => {
      this.implicados = res;

      this.getTestigos();
    });
  }

  getTestigos() {
    this.servicioDenuncia.getTestigo(this.id).subscribe( res => {
      this.testigos = res;
    });
  }

  public captureScreen()
  {
    var data = document.getElementById('pdfTable');

    html2canvas(data, {
      windowHeight: 1200,
      windowWidth: 1200,
    }).then(canvas => {

      // Few necessary setting options
      let imgWidth = 208;
      let pageHeight = 295;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

      let pdfout = pdf.output('datauristring');

      // console.log(pdfsent);

      pdf.save(`Communication_${this.codigo}.pdf`); // Generated PDF




      // var wid;
      // var hgt;
      // var img = canvas.toDataURL("image/png"); //image data of canvas
      // var hratio = hgt / wid;
      // var doc = new jsPDF({
      //   orientation: "landscape"
      // });
      // var width = +doc.internal.pageSize.width;
      // var height = +doc.internal.pageSize.height;
      // var height = width * hratio;
      // doc.addImage(img, 'JPEG', width*.025, 20, width*0.95, height*0.95);
      // doc.save('AssetComparison.pdf');
    });
  }

}


